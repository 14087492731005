/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const analyzeResume = /* GraphQL */ `
  mutation AnalyzeResume($input: AnalyzeResumeInput!) {
    analyzeResume(input: $input) {
      success
      analysis
      error
    }
  }
`;
